<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <div class="d-flex justify-content-between align-items-center">
            <h4 class="page-title">Module Cancel List Report</h4>
            <div>
              <button
                class="ms-3 btn btn-danger btn-sm"
                @click="exportPdfClick()"
              >
                <span
                  v-if="pdfLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ pdfLoading == true ? "Loading..." : "Export PDF" }}
              </button>
              <button
                class="ms-2 btn btn-success btn-sm"
                @click="exportExcelClick()"
              >
                <span
                  v-if="excelLoading"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                {{ excelLoading == true ? "Loading..." : "Export Excel" }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <div class="d-flex flex-wrap align-items-center">
              <!-- <div class="d-flex"> -->
              <div class="me-2">
                  <label class="col-form-label me-1">From Date</label>
                  <div style="width: 150px">
                    <flat-pickr
                      v-model="from_date"
                      class="form-control"
                      placeholder="Select From Date"
                      name="date"
                      @change="getFilterData(from_date, to_date, moduleName, type, trainer, month)"
                    ></flat-pickr>
                  </div>
              </div>
              

               <div class="me-2">
                <label class="col-form-label ms-1 me-1">To Date</label>
                <div style="width: 150px">
                  <flat-pickr
                    v-model="to_date"
                    class="form-control"
                    placeholder="Select From Date"
                    name="date"
                    @change="getFilterData(from_date, to_date, moduleName, type, trainer, month)"
                  ></flat-pickr>
                </div>
               </div>

               <div class="me-2">
                <label class="col-form-label">Module</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="moduleName"
                    :options="modules"
                    :clearable="false"
                    :selectable="(options) => options.id != moduleName?.id"
                    @option:selected="
                      getFilterData(from_date, to_date, moduleName, type, trainer, month)
                    "
                  >
                  </v-select>
                </div>
               </div>

               <div class="me-2">
                <label class="col-form-label ms-1 me-1">Type</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="type"
                    :options="types"
                    :clearable="false"
                    :selectable="(options) => options.id != type?.id"
                    @option:selected="
                     getFilterData(from_date, to_date, moduleName, type, trainer, month)
                    "
                  >
                  </v-select>
                </div>
               </div>

               <div class="me-2">
                <label class="col-form-label ms-1 me-1">Trainer Name</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="trainer"
                    :options="employeeLists"
                    :clearable="false"
                    :selectable="(options) => options.id != trainer?.id"
                    @option:selected="
                      getFilterData(from_date, to_date, moduleName, type, trainer, month)
                    "
                  >
                  </v-select>
                </div>
               </div>

               <div class="me-2">
                <label class="col-form-label ms-1 me-1">Month</label>
                <div style="width: 150px">
                  <v-select
                    label="name"
                    v-model="month"
                    :options="allMonths"
                    :clearable="false"
                    :selectable="(options) => options.id != month?.id"
                    @option:selected="
                      getFilterData(from_date, to_date, moduleName, type, trainer, month)
                    "
                  >
                  </v-select>
                </div>
               </div>

               <div @click="refreshData" class="icon-css pt-4">
                <i style="color: #4a81d4" class="fe-rotate-cw"></i>
              </div>
              <!-- </div> -->
            </div>
            <place-holder v-if="loading"></place-holder>
            <div class="table-responsive mt-4" v-if="!loading">
              <table
                class="table table-striped dt-responsive mb-3"
                id="dataTable"
                style="width: 2200px; margin-right: 2em;"
                v-show="!loading"
              >
                <thead v-if="!loading">
                  <tr>
                    <th>No.</th>
                    <th>Module Name</th>
                    <th>Module Group</th>
                    <th>Employee Type</th>
                    <th style="width: 300px;">Level</th>
                    <th>From Date</th>
                    <th>To Date</th>
                    <th>Enrolled Expired Date</th>
                    <th>Location</th>
                    <th>Trainer Name</th>
                    <th>Start Time</th>
                    <th>Price</th>
                    <th>Cancel Reason</th>
                    <th>Month</th>
                  </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in allCancelModules" :key="index">
                    <td>{{ ++index }}</td>
                    <td>{{ item.module_name }}</td>
                    <td>{{ item.group_name }}</td>
                    <td>{{ item.employee_type }}</td>
                    <td >{{ position_levels_id.filter((pos) => JSON.parse(item.position_levels).includes(pos.id)).map(pos => pos.name).join(", ") }}</td>
                    <td>{{ item.from_date }}</td>
                    <td>{{ item.to_date }}</td>
                    <td>{{ item.enrolled_expired_date }}</td>
                    <td>{{ item.location }}</td>
                    <td>{{ employeeLists
                          .filter((emp) => item.trainer.includes(emp.emp_id))
                          .map((trainer) => trainer.name)
                          .join(", ") }}</td>
                    <td>{{ item.start_time }}</td>
                    <td>{{ item.prices }}</td>
                    <td>{{ item.cancel_reason }}</td>
                    <td>{{ getMonth(item.from_date) }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "datatables.net/js/jquery.dataTables.min.js";
import "datatables.net-bs5/css/dataTables.bootstrap5.min.css";
import "datatables.net-bs5/js/dataTables.bootstrap5.min.js";
import "datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css";
import "datatables.net-responsive-bs5/js/responsive.bootstrap5.min.js";
import $ from "jquery";
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import badgeSuccess from "../../../components/shared/badgeSuccess.vue";
import moment from "moment";
import {months} from '../../../utlis/months';
import { exportExcel } from "../../../utlis/excel-export-utlis";
import { exportPdf } from "../../../utlis/pdf-export-utlis";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  mixins: [userHasPermissions],
  setup() {
    const toast = useToast();
    return { toast };
  },
  components: {
    vSelect,
    flatPickr,
    badgeSuccess,
  },
  data() {
    return {
      from_date: "",
      to_date: "",
      moduleName: "",
      trainer: "",
      type: "",
      month: "",
      allCancelModules: [],
      employeeLists: [],
      modules: [],
      holdginBusiness: [],
      position_levels_id: [],
      types: [
        {id: 1, name: "Program"}, {id: 2, name: "Normal"}
      ],
      allMonths: [],
      headers: ['No', 'Module Name', 'Module Group', 'Employee Type', 'Level', 'From Date', 'To Date', 'Enrolled Expired Date', 'Location', 'Trainer Name', 'Start Time', 'Price', 'Cancel Reason'],
      title: 'Module Cancel List Report',
      loading: false,
      pdfLoading: false,
      excelLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  methods: {
    getMonth(month) {
      return moment(month).format("MMMM");
    },
    async getPositionLevels() {
      await axios
        .get(`${this.baseUrlHRIS}api/position/level`)
        .then((response) => {
          this.position_levels_id = response.data.data;
        })
        .catch(() => {
          this.toast.error("Not Found Position Levels!");
        });
    },
    async getAllEmployee() {
      this.loading = true;
      await axios
        .get(`${this.baseUrlHRIS}api/employees`)
        .then((response) => {
          this.employeeLists = response.data.data;
          this.loading = false;
        });
    },
    async getAllModules() {
      this.loading = true;
      this.$Progress.start();
      await axios.get(`${this.baseUrl}admin/v1/allmodules`).then((response) => {
        this.modules = response.data.data;
        this.loading = false;
        this.$Progress.finish();
      });
    },
    getFilterData(
      from_date,
      to_date,
      moduleName,
      type,
      trainer,
      month
    ) {
      this.clearFilter();
      $.fn.dataTable.ext.search.push(function (settings, data, dataIndex) {
        let fromDateFilter = from_date;
        let fromDateStage = data[5];
        let toDateFilter = to_date;
        let toDateStage = data[6];
        let moduleFilter = moduleName?.name;
        let moduleStage = data[1];
        let typeFilter = type?.name;
        let typeStage = data[3];
        let trainerFilter = trainer?.name;
        let trainerStage = data[9];
        let monthFilter = month?.name;
        let monthStage = data[13];

        if (fromDateFilter && toDateFilter) {
          const itemDate = new Date(data[6]);
          if (
            itemDate >= new Date(from_date) &&
            itemDate <= new Date(to_date) &&
            (moduleFilter == moduleStage || !moduleFilter) &&
            (typeFilter == typeStage || !typeFilter) &&
            (trainerFilter == trainerStage || !trainerFilter) &&
            (monthFilter == monthStage || !monthFilter)
          ) {
            return true;
          }
          return false;
        } else if (
          (fromDateFilter == fromDateStage || !fromDateFilter) &&
          (toDateFilter == toDateStage || !toDateFilter) &&
          (moduleFilter == moduleStage || !moduleFilter) &&
          (typeFilter == typeStage || !typeFilter) &&
          (trainerFilter == trainerStage || !trainerFilter) &&
          (monthFilter == monthStage || !monthFilter)
        ) {
          return true;
        }
        return false;
      });
      $("#dataTable").DataTable().draw();
    },
    async getAllList() { 
      await axios
        .get(`${this.baseUrl}admin/v2/module-cancel-list`)
        .then((response) => {
          this.loading = true;
          this.$Progress.start();
          this.allCancelModules = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch((error) => {
          console.log(error, "error value is");
        });
      //
      $("#dataTable").DataTable().draw();
    },
    refreshData() {
      this.from_date = null;
      this.to_date = null;
      this.moduleName = null;
      this.type = null;
      this.trainer = null;
      this.month = null;
      this.allCancelModules = [];
      this.clearFilter();
      this.getAllList();
    },
    exportPdfClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.pdfLoading = true;
      const sheetData = [];

      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = '';
        sheetData.push(item);
      });
      exportPdf(this.headers, sheetData, this.title);

      this.pdfLoading = false;
    },
    exportExcelClick() {
      const dataArray = $("#dataTable")
        .DataTable()
        .rows({ search: "applied" })
        .data()
        .toArray();
      if (dataArray.length == 0)
        return this.toast.error("There is no data to export!");
      this.excelLoading = true;
      const sheetData = [];

      sheetData.push(this.headers);
      let i = 0;
      dataArray.forEach((item) => {
        item[0] = ++i;
        item[item.length - 1] = '';
        sheetData.push(item);
      });
      exportExcel(sheetData, this.title);
      this.excelLoading = false;
    },
    clearFilter() {
      $.fn.dataTable.ext.search.pop();
      $("#dataTable").DataTable().draw();
    },
  },
  async created() {
    this.clearFilter();
    this.allMonths = months;
    if(this.$store.getters["odoo/getAllEmployees"].length > 0) {
      this.employeeLists = this.$store.getters["odoo/getAllEmployees"];
    }else {
      await this.getAllEmployee();
    }
    await this.getPositionLevels();
    await this.getAllModules();
    await this.getAllList();
  },
};
</script>

<style scoped>
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #4a81d4;
  border-color: #4a81d4;
}
</style>
